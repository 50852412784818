import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './VPSHosting.css';
import '../App.css';
import { faCheckCircle, faRocket, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import heroImage from "../images/hero.png";

const handleBuy = (vps_id) => {
    console.log('Buying VPS Plan: ' + vps_id);

    const urls = {
        '001': 'https://billing.fastvps.systems/store/vps/vps-01',
        '002': 'https://billing.fastvps.systems/store/vps/vps-02',
        '003': 'https://billing.fastvps.systems/store/vps/vps-03',
        '004': 'https://billing.fastvps.systems/store/vps/vps-04',
        '005': 'https://billing.fastvps.systems/store/vps/vps-05',
        '006': 'https://billing.fastvps.systems/store/performance-vps-servers/perf-vps-01',
        '007': 'https://billing.fastvps.systems/store/performance-vps-servers/perf-vps-02',
        '008': 'https://billing.fastvps.systems/store/performance-vps-servers/perf-vps-03',
        '009': 'https://billing.fastvps.systems/store/performance-vps-servers/perf-vps-04',
        '010': 'https://billing.fastvps.systems/store/performance-vps-servers/perf-vps-05',
        '011': 'https://billing.fastvps.systems/store/mem-vps/mem-vps-01',
        '012': 'https://billing.fastvps.systems/store/mem-vps/mem-vps-02',
        '013': 'https://billing.fastvps.systems/store/mem-vps/mem-vps-03',
        '014': 'https://billing.fastvps.systems/store/mem-vps/mem-vps-04',
        '015': 'https://billing.fastvps.systems/store/mem-vps/mem-vps-05',
        '016': 'https://billing.fastvps.systems/store/ryzen-9-7950x3d-vds/vds-game-01',
        '017': 'https://billing.fastvps.systems/store/ryzen-9-7950x3d-vds/vds-game-02',
        '018': 'https://billing.fastvps.systems/store/ryzen-9-7950x3d-vds/vds-game-03',
        '019': 'https://billing.fastvps.systems/store/ryzen-9-7950x3d-vds/vds-game-04',
        '020': 'https://billing.fastvps.systems/store/ryzen-9-7950x3d-vds/vds-game-05',
    };

    // Open the URL in a new tab
    window.open(urls[vps_id], '_blank');
};

const plans = [
    { id: '001', planId: 'VPS-01', category: 'General Purpose', vCores: 1, ram: '1GB', storage: '30GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 3.99 },
    { id: '002', planId: 'VPS-02', category: 'General Purpose', vCores: 2, ram: '4GB', storage: '60GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 5.99 },
    { id: '003', planId: 'VPS-03', category: 'General Purpose', vCores: 2, ram: '8GB', storage: '80GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 8.99 },
    { id: '004', planId: 'VPS-04', category: 'General Purpose', vCores: 4, ram: '16GB', storage: '120GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 10.99 },
    { id: '005', planId: 'VPS-05', category: 'General Purpose', vCores: 6, ram: '24GB', storage: '160GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 14.99 },
    { id: '006', planId: 'PERF-VPS-01', category: 'CPU optimized', vCores: 2, ram: '6GB', storage: '50GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 7.99 },
    { id: '007', planId: 'PERF-VPS-02', category: 'CPU optimized', vCores: 6, ram: '12GB', storage: '50GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 11.99 },
    { id: '008', planId: 'PERF-VPS-03', category: 'CPU optimized', vCores: 8, ram: '16GB', storage: '150GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 15.99 },
    { id: '009', planId: 'PERF-VPS-04', category: 'CPU optimized', vCores: 10, ram: '16GB', storage: '200GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 19.99 },
    { id: '010', planId: 'PERF-VPS-05', category: 'CPU optimized', vCores: 12, ram: '32GB', storage: '250GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 24.99 },
    { id: '011', planId: 'MEM-VPS-01', category: 'Memory optimized', vCores: 2, ram: '12GB', storage: '150GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 9.99 },
    { id: '012', planId: 'MEM-VPS-02', category: 'Memory optimized', vCores: 3, ram: '24GB', storage: '150GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 14.99 },
    { id: '013', planId: 'MEM-VPS-03', category: 'Memory optimized', vCores: 4, ram: '32GB', storage: '200GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 24.99 },
    { id: '014', planId: 'MEM-VPS-04', category: 'Memory optimized', vCores: 6, ram: '64GB', storage: '300GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 44.99 },
    { id: '015', planId: 'MEM-VPS-05', category: 'Memory optimized', vCores: 8, ram: '128GB', storage: '500GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'UK', price: 64.99 },
    { id: '016', planId: 'VDS-GAME-01', category: 'Ryzen 9 7950X3D VDS', vCores: 2, ram: '6GB', storage: '80GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 14.99 },
    { id: '017', planId: 'VDS-GAME-02', category: 'Ryzen 9 7950X3D VDS', vCores: 4, ram: '12GB', storage: '120GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 22.99 },
    { id: '018', planId: 'VDS-GAME-03', category: 'Ryzen 9 7950X3D VDS', vCores: 8, ram: '18GB', storage: '200GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 28.99 },
    { id: '019', planId: 'VDS-GAME-04', category: 'Ryzen 9 7950X3D VDS', vCores: 8, ram: '24GB', storage: '300GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 36.99 },
    { id: '020', planId: 'VDS-GAME-05', category: 'Ryzen 9 7950X3D VDS', vCores: 12, ram: '36GB', storage: '400GB SSD', bandwidth: 'Unmetered 1Gbps', location: 'GER', price: 55.99 },
];

const VPSHosting = ({ isDarkmode, toggleDarkMode }) => {
    const [sortedPlans, setSortedPlans] = useState(plans);
    const [sortType, setSortType] = useState('price');

    const handleSort = (event) => {
        const value = event.target.value;
        setSortType(value);

        let sortedArray = [...sortedPlans];
        if (value === 'cpu') {
            sortedArray.sort((a, b) => a.vCores - b.vCores);
        } else if (value === 'memory') {
            sortedArray.sort((a, b) => parseInt(a.ram) - parseInt(b.ram));
        } else if (value === 'price') {
            sortedArray.sort((a, b) => a.price - b.price);
        } else if (value === 'category') {
            sortedArray.sort((a, b) => a.category.localeCompare(b.category));
        }
        setSortedPlans(sortedArray);
    };

    return (
        <div className={`page-content ${isDarkmode ? 'darkmode' : ''}`}>
            <div className={`game-hero ${isDarkmode ? 'darkmode' : ''}`}>
                <div className="game-hero-left">
                    <h1 id='hero-title-vps' className="vps-hero-title">VPS HOSTING</h1>
                    <h3>Rocket Fast Hosting, Without the NASA Prices</h3>
                    <h5>All Our Services Come Equiped With Path.net DDoS Protection. Enhance Your Presence With Their State Of The Art Filters</h5>
                </div>
                <div className="hero-right">
                    <img src={heroImage} alt="VPS hosting illustration" />
                </div>
            </div>
            <div className='feature-container'>
                <div className='feature'>
                    <FontAwesomeIcon icon={faRocket} size='8x' />
                    <h2>Fast</h2>
                    <p>Experience blazing fast speeds with our state-of-the-art hardware, optimized networks, and low-latency connections. We prioritize speed to keep your services running smoothly and efficiently.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faShieldAlt} size='8x' />
                    <h2>Secure</h2>
                    <p>Boasting Leading DDoS Protection, On-Site Redundancy Routes, Around The Clock Monitoring. We Have Your Security In Mind At Every Point Of Your Services.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faCheckCircle} size='8x' />
                    <h2>Reliable</h2>
                    <p>With an industry-leading uptime guarantee, redundant infrastructure, and proactive maintenance, we ensure that your services are always available when you need them.</p>
                </div>
            </div>

            

            <div className={`vps-plans-container ${isDarkmode ? 'darkmode' : ''}`}>
                <div className={`sort-container ${isDarkmode ? 'darkmode' : ''}`}>
                    <label htmlFor='sort'>Sort by: </label>
                    <select id='sort' value={sortType} onChange={handleSort}>
                        <option value='cpu'>CPU #</option>
                        <option value='memory'>Memory #</option>
                        <option value='price'>Price #</option>
                        <option value='category'>Category</option>
                    </select>
                </div>
                <div className={`vps-plan-header ${isDarkmode ? 'darkmode' : ''}`}>
                    <h4>Plan ID</h4>
                    <h4>Category</h4>
                    <h4>vCores</h4>
                    <h4>RAM</h4>
                    <h4>Storage</h4>
                    <h4>Bandwidth</h4>
                    <h4>Location</h4>
                    <h4>Price/Month</h4>
                    <h4>Action</h4>
                </div>
                {sortedPlans.map((plan) => (
                    <div className={`vps-plan ${isDarkmode ? 'darkmode' : ''}`}key={plan.id}>
                        <p>{plan.planId}</p>
                        <p>{plan.category}</p>
                        <p>{plan.vCores}</p>
                        <p>{plan.ram}</p>
                        <p>{plan.storage}</p>
                        <p>{plan.bandwidth}</p>
                        <p>{plan.location}</p>
                        <p>€{plan.price.toFixed(2)}</p>
                        <button onClick={() => handleBuy(plan.id)}>Buy Now</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VPSHosting;
