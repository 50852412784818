import React from 'react';
import './About.css';

const About = ({ isDarkmode }) => {
    return (
        <div className={`page-container ${isDarkmode ? "darkmode" : ""}`}>
            <div className="page-content">
                <div className="about-header">
                    <h1>About FastVPS.Systems</h1>
                </div>
                <p>
                    Welcome to FastVPS.Systems! Since our inception in 2023, we have been dedicated to providing high-quality hosting solutions.
                    Our services include VPS hosting, game hosting, and web hosting, with a presence in the UK and Germany.
                </p>
                <p>
                    Trusted by over 100 clients, we pride ourselves on our reliability and top-notch customer service. Our offerings are designed
                    to meet a wide range of needs, ensuring that our clients have the best hosting experience possible.
                </p>
                <h2>Our Services</h2>
                <ul>
                    <li>VPS Hosting</li>
                    <li>Game Hosting</li>
                    <li>Web Hosting</li>
                </ul>
                <h2>Why Choose Us?</h2>
                <p>
                    At FastVPS.Systems, we provide advanced DDoS protection via path.net, ensuring your services are secure and reliable. Our
                    protection includes various filters and rules to safeguard your data. Additionally, all our VPS and game servers come with
                    Anycast A2S caching, enhancing your server performance and reducing latency.
                </p>
                <h2>Our Commitment</h2>
                <p>
                    We are committed to delivering high-performance hosting solutions backed by exceptional customer support. Whether you are a
                    business or an individual, our hosting services are designed to meet your unique needs.
                </p>
                <p>
                    Join us at FastVPS.Systems and experience the difference.
                </p>
            </div>
        </div>
    );
};

export default About;
