import React, { useEffect, useRef } from "react";

const TrustBox = () => {
  const trustBoxRef = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, []);

  return (
    <div>
      

      <div class="trustpilot-widget" data-locale="en-GB" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="6615af32f8cce418cc88152e" data-style-height="150px" data-style-width="100%">
        <a href="https://uk.trustpilot.com/review/fastvps.systems" target="_blank" rel="noopener">Trustpilot</a>
      </div>
    </div>
  );
};

export default TrustBox;
