import './FourOFour.css';
import FOR from '../images/FOF2.png'

const FourOFour = ({ isDarkmode }) => {
    return (
        <div className={`fourofour ${isDarkmode ? 'darkmode' : ''}`}>
            <div className={`fourofour-container ${isDarkmode ? 'darkmode' : ''}`}>
                <img src={FOR} alt='404' />
                <p>Page Not Found</p>
                <a href='/'>Back To Safety</a>
                </div>
        </div>
    );
    }

export default FourOFour;