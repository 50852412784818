import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <h4>Company</h4>
                    <ul>
                        <li>
                            <Link to="/about">About Us</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                            <Link to="https://discord.gg/p8PYgWyT23">Discord Server</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Resources</h4>
                    <ul>
                        <li>
                            <Link to="/faq">FAQ</Link>
                        </li>
                        <li>
                            <Link to="/blog">Blog?</Link>
                        </li>
                        <li>
                            <Link to="https://billing2.fastvps.systems/submitticket.php" target="_blank">Support</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Legal</h4>
                    <ul>
                        <li>
                            <Link to="/terms">Terms of Service</Link>
                        </li>
                        <li>
                            <Link to="/privacy">Privacy Policy</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Contact</h4>
                    <p>Email: support@fastvps.com</p>
                    <p>Address: Unit C, Blackett Street, Manchester</p>
                    <p>Company Number: 15315910</p>
                    <p>FastVPS.Systems Trademarked Under Cyphersoftware Solutions Ltd</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 FastVPS. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;