import React from 'react';
import './TermsOfService.css';

const TermsOfService = ( { isDarkmode}) => {
    return (
        <div className="tos-container">
            <div className="tos-header">
                <h1>Terms of Service</h1>
            </div>
            <div className="tos-content">
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>1. Introduction</h2>
                    <p>Welcome to FastVPS.Systems. By using our services, you agree to comply with and be bound by the following terms and conditions. Please review them carefully. If you do not agree to these terms, you should not use our services.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>2. Definitions</h2>
                    <p><strong>Company</strong>: Refers to FastVPS.Systems.</p>
                    <p><strong>Client</strong>: Refers to the individual or entity using the services provided by the Company.</p>
                    <p><strong>Services</strong>: Refers to the hosting solutions provided by FastVPS.Systems, including VPS hosting, game hosting, and web hosting.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>3. Use of Services</h2>
                    <h3>3.1 Eligibility</h3>
                    <p>You must be at least 18 years old to use our services. By using our services, you represent and warrant that you have the right, authority, and capacity to enter into this agreement and to abide by all the terms and conditions of this agreement.</p>
                    <h3>3.2 Acceptable Use</h3>
                    <p>You agree to use the services only for lawful purposes. You are prohibited from using the services to:</p>
                   
                        <li>Engage in any illegal activity.</li>
                        <li>Distribute malware or other harmful software.</li>
                        <li>Host or distribute content that is abusive, obscene, defamatory, or otherwise offensive.</li>
                        <li>Engage in any activity that interferes with or disrupts the services provided by the Company.</li>
                    
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>4. Service Availability</h2>
                    <p>The Company will use reasonable efforts to ensure that the services are available at all times. However, the Company does not guarantee that the services will be uninterrupted or error-free. The Company shall not be liable for any interruptions or errors in the services.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>5. Payment and Fees</h2>
                    <h3>5.1 Fees</h3>
                    <p>The Client agrees to pay the fees for the services as set forth on the Company's website. All fees are non-refundable unless otherwise stated.</p>
                    <h3>5.2 Payment Terms</h3>
                    <p>Fees are due and payable upon the commencement of the service period. The Company reserves the right to suspend or terminate services for any account that is past due.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>6. Security</h2>
                    <p>The Company provides advanced DDoS protection and security measures to safeguard your data. However, the Company does not guarantee that its security measures will prevent all security breaches. The Client is responsible for maintaining the security of their account credentials.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>7. Data Privacy</h2>
                    <p>The Company respects the privacy of its clients. Please refer to the Company's Privacy Policy for information on how the Company collects, uses, and discloses personal information.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>8. Termination</h2>
                    <p>The Company reserves the right to terminate or suspend the Client's access to the services at any time, without notice, for conduct that the Company believes violates these terms or is harmful to other clients or the Company.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>9. Limitation of Liability</h2>
                    <p>To the maximum extent permitted by law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) the Client's use or inability to use the services; (ii) any unauthorized access to or use of the Company's servers and/or any personal information stored therein.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>10. Indemnification</h2>
                    <p>The Client agrees to indemnify and hold harmless the Company, its officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with the Client's access to or use of the services.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>11. Changes to Terms</h2>
                    <p>The Company reserves the right to modify these terms at any time. The Client is responsible for regularly reviewing these terms. Continued use of the services after any such changes shall constitute the Client's consent to such changes.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>12. Governing Law</h2>
                    <p>These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which the Company is established, without regard to its conflict of law principles.</p>
                </section>
                <section className={`tos-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>13. Contact Information</h2>
                    <p>If you have any questions about these terms, please contact us at support@fastvps.systems.</p>
                </section>
            </div>
        </div>
    );
};

export default TermsOfService;
