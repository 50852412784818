import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = ({ isDarkmode }) => {
    return (
        <div className="policy-container">
            <div className="policy-header">
                <h1>Privacy Policy</h1>
            </div>
            <div className="policy-content">
                <section className={`policy-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>1. Introduction</h2>
                    <p>At FastVPS.Systems, we value your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. Please read this policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>
                </section>
                <section className={`policy-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>2. Information We Collect</h2>
                    <h3>2.1 Personal Data</h3>
                    <p>We may collect personal data that you voluntarily provide to us when you register on the website, make a purchase, or contact us. This may include:</p>
                    <ul>
                        <li>Name</li>
                        <li>Email Address</li>
                        <li>Phone Number</li>
                        <li>Payment Information</li>
                    </ul>
                    <h3>2.2 Usage Data</h3>
                    <p>We may also collect information about your interactions with our website. This data helps us understand how our website is used and improve our services. This may include:</p>
                    <ul>
                        <li>IP Address</li>
                        <li>Browser Type</li>
                        <li>Operating System</li>
                        <li>Pages Visited</li>
                        <li>Time and Date of Visits</li>
                    </ul>
                </section>
                <section className={`policy-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>3. How We Use Your Information</h2>
                    <p>We use the information we collect in the following ways:</p>
                    <ul>
                        <li>To provide and maintain our services</li>
                        <li>To process transactions</li>
                        <li>To communicate with you</li>
                        <li>To improve our website and services</li>
                        <li>To enforce our terms and conditions</li>
                    </ul>
                </section>
                <section className={`policy-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>4. Disclosure of Your Information</h2>
                    <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
                    <ul>
                        <li>By Law or to Protect Rights</li>
                        <li>To Trusted Third Parties</li>
                        <li>Business Transfers</li>
                    </ul>
                </section>
                <section className={`policy-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>5. Security of Your Information</h2>
                    <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
                </section>
                <section className={`policy-section ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>6. Contact Us</h2>
                    <p>If you have questions or comments about this Privacy Policy, please contact us at support@fastvps.systems.</p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
