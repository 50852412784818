import React, { useState } from 'react';
import axios from 'axios';
import './ContactForm.css';

const ContactForm = ({ isDarkmode }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const webhookUrl = 'https://discord.com/api/webhooks/1226655656879325204/fb14H5ihmeyPH0ubGxY7MqHtpxpzDS6FHhYMq5caZRfUQcm6cSnNldSeGzU53TzReCrV';

        const payload = {
            content: null,
            embeds: [
                {
                    title: `New Contact Form Submission from ${formData.name}`,
                    color: null,
                    fields: [
                        {
                            name: 'Name',
                            value: formData.name
                        },
                        {
                            name: 'Email',
                            value: formData.email
                        },
                        {
                            name: 'Message',
                            value: formData.message
                        }
                    ]
                }
            ],
            attachments: []
        };

        try {
            await axios.post(webhookUrl, payload);
            window.location.href = '/contact/success';
        } catch (error) {
            console.error('Error sending message to Discord webhook', error);
            alert('There was an error sending your message. Please try again later.');
        }
    };

    return (
        <form className={`contact-form ${isDarkmode ? 'darkmode' : ''}`} onSubmit={handleSubmit}>
            <h2>Contact Us</h2>
            <div className="form-group">
                <label>Name:</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-group">
                <label>Email:</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-group">
                <label>Message:</label>
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                />
            </div>
            <button type="submit" className="submit-button">Send</button>
        </form>
    );
};

export default ContactForm;
