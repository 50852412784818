import logo from '../images/logo.webp'
import hero from '../images/hero.png'

import '../App.css'

const UnderConstruction = ({ isDarkmode }) => {
    return (
        <div className={`page-content ${isDarkmode ? 'darkmode' : ''}`}>
            <div className={`game-hero ${isDarkmode ? 'darkmode' : ''}`}>
                <div className="game-hero-left">
                    <h1 id='game-title-vps' className="vps-hero-title">UNDER CONSTRUCTION</h1>
                    <h3>Check back soon for updates</h3>
                    <a href='/' className='hero-button'>Go Back Home</a>    
                </div>
                <div className="hero-right construction">
                    <img src={hero} alt="Under construction illustration" />
                </div>
            </div>
        </div>
    );
}


export default UnderConstruction;