import React from 'react';
import './GameHosting.css';
import heroImage from "../images/hero.png";
import { faCheckCircle, faRocket, faShieldAlt, faPeopleGroup} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const handleBuy = (plan_id) => {
    console.log('Buying Game Hosting Plan: ' + plan_id);

    const url_dict = {
        'rust-01': 'https://billing.fastvps.systems/store/rust-hosting/lowgrade-plan',
        'rust-02': 'https://billing.fastvps.systems/store/rust-hosting/crude-oil-plan',
        'rust-03': 'https://billing.fastvps.systems/store/rust-hosting/metal-plan',
        'rust-04': 'https://billing.fastvps.systems/store/rust-hosting/sulfur-plan',
        'mc-01': 'https://billing.fastvps.systems/store/',
        'mc-02': 'https://billing.fastvps.systems/store/',
        'mc-03': 'https://billing.fastvps.systems/store/',
        'mc-04': 'https://billing.fastvps.systems/store/',
        'mc-05': 'https://billing.fastvps.systems/store/'
    }

    // Open the URL in a new tab
    window.open(url_dict[plan_id], '_blank');
};
const GameHosting = ({ isDarkmode }) => {
    const rustPlans = [
        { id: 'rust-01', description: 'Ideal For 1-10 Players', name: "Lowgrade Plan", price: "€5.99 EUR/month", cpu: "2 vCore (Ryzen 9 7950X3D)", memory: "6GB", storage: "60GB SSD", ports: "4 Port Allocations", protection: "Path.net", location: "Germany ONLY" },
        { id: 'rust-02', description: 'Ideal For 20-40 Players', name: "Crude Oil Plan", price: "€11.99 EUR/month", cpu: "4 vCore (Ryzen 9 7950X3D)", memory: "10GB", storage: "80GB SSD", ports: "4 Port Allocations", protection: "Path.net", location: "Germany ONLY" },
        { id: 'rust-03', description: 'Ideal For 50-100 Players', name: "Metal Plan", price: "€14.99 EUR/month", cpu: "4 vCore (Ryzen 9 7950X3D)", memory: "12GB", storage: "100GB SSD", ports: "4 Port Allocations", protection: "Path.net", location: "Germany ONLY" },
        { id: 'rust-04', description: 'Ideal For 100-200 Players', name: "Sulfur Plan", price: "€17.99 EUR/month", cpu: "6 vCore (Ryzen 9 7950X3D)", memory: "16GB", storage: "150GB SSD", network: "Dedicated IPV4", protection: "Path.net", location: "Germany ONLY" }
    ];

    const minecraftPlans = [
        { id: 'mc-01', description: 'Ideal For Vanilla Gamemodes', name: "Wood Plan", price: "€0.99 EUR/month", cpu: "2 vCore", memory: "2GB", storage: "5GB SSD", protection: "Path.net" },
        { id: 'mc-02', description: 'Ideal For Slightly Modded', name: "Cobble Plan", price: "€2.49 EUR/month", cpu: "3 vCore", memory: "4GB", storage: "10GB SSD", protection: "Path.net" },
        { id: 'mc-03', description: 'Ideal For Slightly Modded Community', name: "Iron Plan", price: "€4.99 EUR/month", cpu: "4 vCore", memory: "8GB", storage: "15GB SSD", protection: "Path.net" },
        { id: 'mc-04', description: 'Ideal For Modded Community', name: "Diamond Plan", price: "€6.99 EUR/month", cpu: "6 vCore", memory: "12GB", storage: "20GB SSD", protection: "Path.net" },
        { id: 'mc-05', description: 'Ideal For Large Community', name: "Obsidian Plan", price: "€12.99 EUR/month", cpu: "8 vCore", memory: "18GB", storage: "25GB SSD", protection: "Path.net" }
    ];

    const PlanCard = ({ plan }) => (
        <div className={`plan-card ${isDarkmode ? 'darkmode' : ''}`}>
            <h4>{plan.name}</h4>
            <p><b>{plan.description}</b></p>
            <ul>
                <li><strong>Price:</strong> {plan.price}</li>
                <li><strong>CPU:</strong> {plan.cpu}</li>
                <li><strong>Memory:</strong> {plan.memory}</li>
                {plan.storage && <li><strong>Storage:</strong> {plan.storage}</li>}
                {plan.ports && <li><strong>Ports:</strong> {plan.ports}</li>}
                {plan.network && <li><strong>Network:</strong> {plan.network}</li>}
                <li><strong>Protection:</strong> {plan.protection}</li>
                {plan.location && <li><strong>Location:</strong> {plan.location}</li>}
                <button className="buy-button" onClick={() => handleBuy(plan.id)}>Buy Now</button>
            </ul>
        </div>
    );

    return (
        <div className={`page-content ${isDarkmode ? 'darkmode' : ''}`}>
            <div className={`game-hero ${isDarkmode ? 'darkmode' : ''}`}>
                <div className="game-hero-left">
                    <h1 id='game-title-vps' className="vps-hero-title">GAME HOSTING</h1>
                    <h3>Accelerate Your Community With Rocket Fast Performance</h3>
                    <h5>Stay Online 24/7 With Path.net. Global Low Latency With A2S Caching Included With All Plans</h5>
                </div>
                <div className="hero-right">
                    <img src={heroImage} alt="VPS hosting illustration" />
                </div>
            </div>
            <div className='feature-container'>
                <div className='feature'>
                    <FontAwesomeIcon icon={faRocket} size='8x' />
                    <h2>Fast</h2>
                    <p>Experience blazing fast speeds with our state-of-the-art hardware, optimized networks, and low-latency connections. We prioritize speed to keep your services running smoothly and efficiently.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faShieldAlt} size='8x' />
                    <h2>Secure</h2>
                    <p>Boasting Leading DDoS Protection, On-Site Redundancy Routes, Around The Clock Monitoring. We Have Your Security In Mind At Every Point Of Your Services.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faCheckCircle} size='8x' />
                    <h2>Reliable</h2>
                    <p>With an industry-leading uptime guarantee, redundant infrastructure, and proactive maintenance, we ensure that your services are always available when you need them.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faPeopleGroup} size='8x' />
                    <h2>24/7 Support</h2>
                    <p>We Offer 24/7 Support For Our Clients, Ensuring their servers run smoothly day and night. Offering A Wealth Of Experience In The Gaming Industry To Reach Your Audience</p>
                </div>
            </div>
            <div className="plans-container">
                <h2>Rust Server Hosting Plans</h2>
                <div className="plans-grid">
                    {rustPlans.map(plan => <PlanCard key={plan.name} plan={plan} />)}
                </div>
                <h2>Minecraft Server Hosting Plans</h2>
                <div className="plans-grid">
                    {minecraftPlans.map(plan => <PlanCard key={plan.name} plan={plan} />)}
                </div>
            </div>
        </div>
    );
}

export default GameHosting;
