import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import hero_image from "../images/hero.png"
import { faSun, faMoon, faBars, faTimes, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./NavBar.css";

const NavBar = ({ isDarkmode, toggleDarkMode }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // If the Mobile Menu is open, close it when the user scrolls
    useEffect(() => {
        if (isMenuOpen) {
            window.addEventListener("scroll", () => setIsMenuOpen(false));
            return () => window.removeEventListener("scroll", () => setIsMenuOpen(false));
        }
    }, [isMenuOpen]);

    // Colapse the Mobile Menu when the user clicks on a link
    useEffect(() => {
        if (isMenuOpen) {
            const links = document.querySelectorAll(".nav-link");
            links.forEach((link) => link.addEventListener("click", () => setIsMenuOpen(false)));
            return () => links.forEach((link) => link.removeEventListener("click", () => setIsMenuOpen(false)));
        }
    } , [isMenuOpen]);

   
   useEffect(() => {
        const dropdownbtn = document.querySelector(".dropbtn");
        const dropdowncontent = document.querySelector(".dropdown-content");
        const dropdown_icon = document.querySelector(".chevron-icon");
        dropdownbtn.addEventListener("click", () => {
            if (dropdowncontent.style.display === "block") {
                dropdowncontent.style.display = "none";

            } else {
                dropdowncontent.style.display = "block";
            }
        });
    }, []);

    



    const handleSignup = () => {
        // Open the URL in a new tab
        window.open("https://billing.fastvps.systems/register.php", "_blank");
    };

    return (
        <header className={`App-header ${isScrolled ? "scrolled" : ""} ${isDarkmode ? "darkmode" : ""}`}>
            <div className="logo"> 
                <img
                    src={hero_image}
                    alt="Logo"
                />
            </div>
            <nav className={isMenuOpen ? "open" : ""}>
                <Link to="/" className="nav-link">WHY FASTVPS</Link>
                <div className="dropdown">
                    <button className="dropbtn">
                        SOLUTIONS <FontAwesomeIcon icon={faChevronDown} className="chevron-icon" />
                    </button>
                    <div className={`dropdown-content ${isDarkmode ? "darkmode" : ""}`}>
                        <Link to="/services/vps-hosting" >VPS Hosting</Link>
                        <Link to="/services/game-hosting" >Game Hosting</Link>
                        <Link to="/services/web-hosting" >Web Hosting</Link>
                        <Link to="/services/discord-bot-hosting" >Bot Hosting</Link>
                    </div>
                </div>
                <Link to="/resources" className="nav-link">RESOURCES</Link>
                <Link to="/blog" className="nav-link">BLOG</Link>
                <Link to="/contact" className="nav-link">CONTACT SALES</Link>
                <button className="signup-button" onClick={handleSignup} >SIGNUP</button>
                <button className="toggle-darkmode" onClick={toggleDarkMode}>
                    <FontAwesomeIcon icon={isDarkmode ? faSun : faMoon} />
                </button>
            </nav>
            <button className="menu-toggle" onClick={handleMenuToggle}>
                <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
            </button>
        </header>
    );
};

export default NavBar;
