import React from 'react';
import './Contact.css';
import ContactForm from '../components/ContactForm';

const Contact = ({ isDarkmode, toggleDarkMode }) => {
    return (
        <div className={`contact-page ${isDarkmode ? 'darkmode' : ''}`}>
            <h1>Contact Us</h1>
            <h4>Here At FastVPS.Systems, We Believe in Fast Reliable Communications Between You and Ourselves. We want to offer the fastest and helpful Support. We Offer 24/7/365 Support For You And Your Business. For General Questions Please Use The Below Form. For Pre-existing Customers Please Visit <a href='https://billing2.fastvps.systems/submitticket.php'>Client Tickets</a></h4>
            <ContactForm isDarkmode={isDarkmode} />
        </div>
    );
};

export default Contact;
