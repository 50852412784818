import React from 'react';
import './WebHosting.css';
import heroImage from "../images/hero.png";
import { faCheckCircle, faRocket, faShieldAlt, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const handleBuy = (plan_id) => {
    console.log('Buying Game Hosting Plan: ' + plan_id);
    // Add your code here to handle the purchase
    // Send the user to the checkout page or open a modal

        document.location.href = webPlans.find(plan => plan.id === plan_id).link
};


const webPlans = [
    { id: 'web-01', name: 'Starter', price: '€2.99', features: ['1 Website', '1 Email Account', '1GB SSD Storage', '5 SQL Databases', 'Unmetered Bandwidth', 'Infinite Sub Domains', '0 Addon Domains', 'Free SSL Certificate', '1-Click WordPress Install', '24/7 Support'], link: 'https://billing2.fastvps.systems/index.php?rp=/store/web-hosting/starter'},
    { id: 'web-02', name: 'Startup Business', price: '€5.99', features: ['2 Websites', '5 Email Accounts', '4GB SSD Storage', '5 SQL Databases', 'Unmetered Bandwidth', 'Infinite Sub Domains', '1 Addon Domain', 'Free SSL Certificate', '1-Click WordPress Install', '24/7 Support'], link: 'https://billing2.fastvps.systems/index.php?rp=/store/web-hosting/startup-business'},
    { id: 'web-03', name: 'Business', price: '€10.99', features: ['3 Websites', '10 Email Accounts', '8GB SSD Storage', '5 SQL Databases', 'Unmetered Bandwidth', 'Infinite Sub Domains', '2 Addon Domain', 'Free SSL Certificate', '1-Click WordPress Install', '24/7 Support'], link: 'https://billing2.fastvps.systems/index.php?rp=/store/web-hosting/business'},
    { id: 'web-04', name: 'Enterprise', price: '€16.99', features: ['4 Websites', '15 Email Accounts', '12GB SSD Storage', '10 SQL Databases', 'Unmetered Bandwidth', 'Infinite Sub Domains', '3 Addon Domains', 'Free SSL Certificate', '1-Click WordPress Install', '24/7 Support'], link: 'https://billing2.fastvps.systems/index.php?rp=/store/web-hosting/enterprise'},
    { id: 'web-05', name: 'Evil Corporation', price: '€24.99', features: ['6 Websites', '30 Email Accounts', '16GB SSD Storage', '20 SQL Databases', 'Unmetered Bandwidth', 'Infinite Sub Domains', '5 Addon Domains', 'Free SSL Certificate', '1-Click WordPress Install', '24/7 Support'], link: 'https://billing2.fastvps.systems/index.php?rp=/store/web-hosting/evil-corporation' }
]
const WebHosting = ({ isDarkmode }) => {
    return (
        <div className={`page-content ${isDarkmode ? 'darkmode' : ''}`}>
            <div className={`game-hero ${isDarkmode ? 'darkmode' : ''}`}>
                <div className="game-hero-left">
                    <h1 id='game-title-vps' className="vps-hero-title">WEB HOSTING</h1>
                    <h3>Accelerate Your Community With Rocket Fast Performance</h3>
                    <h5>Stay Online 24/7 With Path.net. Global Low Latency With A2S Caching Included With All Plans</h5>
                </div>
                <div className="hero-right">
                    <img src={heroImage} alt="VPS hosting illustration" />
                </div>
            </div>
            <div className='feature-container'>
                <div className='feature'>
                    <FontAwesomeIcon icon={faRocket} size='8x' />
                    <h2>Fast</h2>
                    <p>Experience blazing fast speeds with our state-of-the-art hardware, optimized networks, and low-latency connections. We prioritize speed to keep your services running smoothly and efficiently.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faShieldAlt} size='8x' />
                    <h2>Secure</h2>
                    <p>Boasting Leading DDoS Protection, On-Site Redundancy Routes, Around The Clock Monitoring. We Have Your Security In Mind At Every Point Of Your Services.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faCheckCircle} size='8x' />
                    <h2>Reliable</h2>
                    <p>With an industry-leading uptime guarantee, redundant infrastructure, and proactive maintenance, we ensure that your services are always available when you need them.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faPeopleGroup} size='8x' />
                    <h2>24/7 Support</h2>
                    <p>We Offer 24/7 Support For Our Clients, Ensuring their Websites run smoothly day and night. Offering A Wealth Of Experience In The Web Industry To Reach Your Audience</p>
                </div>
            </div>

            <div className='webplans-info'>
                <h1>Web Hosting Plans</h1>
                <p>Choose from a variety of web hosting plans to suit your needs. Whether you're just starting out or need a more robust solution, we have you covered. Offering 24/7 support, free SSL certificates, and 1-click WordPress installs, we make it easy to get started.<br/>Why Not Start The Right Way With FastVPS.Systems!


                </p>
            </div>
            <div className="web-plans-container">
                <div className={`webplan short ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>{webPlans[0].name}</h2>
                    <h2>{webPlans[0].price}/Month</h2>

                    <ul>
                        {webPlans[0].features.map((feature, index) => (
                            <li key={index}><strong>{feature}</strong></li>
                        ))}
                    </ul>
                    <button className="buy-button" onClick={() => handleBuy(webPlans[0].id)}>Buy Now</button>
                </div>
                <div className={`webplan medium ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>{webPlans[1].name}</h2>
                    <h2>{webPlans[1].price}/Month</h2>
                    <ul>
                        {webPlans[1].features.map((feature, index) => (
                            <li key={index}><strong>{feature}</strong></li>
                        ))}
                    </ul>
                    <button className="buy-button" onClick={() => handleBuy(webPlans[1].id)}>Buy Now</button>
                </div>
                <div className={`webplan long ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>{webPlans[2].name}</h2>
                    <h2>{webPlans[2].price}/Month</h2>
                    <ul>
                        {webPlans[2].features.map((feature, index) => (
                            <li key={index}><strong>{feature}</strong></li>
                        ))}
                    </ul>
                    <button className="buy-button" onClick={() => handleBuy(webPlans[2].id)}>Buy Now</button>
                </div>
                <div className={`webplan medium ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>{webPlans[3].name}</h2>
                    <h2>{webPlans[3].price}/Month</h2>
                    <ul>
                        {webPlans[3].features.map((feature, index) => (
                            <li key={index}><strong>{feature}</strong></li>
                        ))}
                    </ul>
                    <button className="buy-button" onClick={() => handleBuy(webPlans[3].id)}>Buy Now</button>
                </div>
                <div className={`webplan short ${isDarkmode ? 'darkmode' : ''}`}>
                    <h2>{webPlans[4].name}</h2>
                    <h2>{webPlans[4].price}/Month</h2>
                    <ul>
                        {webPlans[4].features.map((feature, index) => (
                            <li key={index}><strong>{feature}</strong></li>
                        ))}
                    </ul>
                    <button className="buy-button" onClick={() => handleBuy(webPlans[4].id)}>Buy Now</button>
                </div>
            </div>

        </div>
    );
}

export default WebHosting;
