import React, { useEffect, useState } from "react";
import TrustBox from "../components/TrustBox";
import heroImage from "../images/hero.png"; // Use the correct path to your uploaded image
import '../GlobalStyling/TypingEffect.css';

const handle_GetStarted = () => {
    window.location.href = '/services/vps-hosting';
}

const hero_titles = [
    "VPS",
    "GAME",
    "WEB",
    "BOT"
];

function Home({ isDarkmode }) {
    const [titleIndex, setTitleIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false); // Start fade-out
            setTimeout(() => {
                setTitleIndex((prevIndex) => (prevIndex + 1) % hero_titles.length);
                setFade(true); // Start fade-in
            }, 1000); // Match the fade-out duration
        }, 4000); // Adjust this value to control the total time each title stays visible

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={`home-content ${isDarkmode ? 'darkmode' : ''}`}>
            <div className={`hero ${isDarkmode ? 'darkmode' : ''}`}>
                <div className="hero-left">
                    <h1 id='hero-title-vps' className="hero-title">
                        <span className={`hero-category ${fade ? 'fade-in' : 'fade-out'}`}>
                            {hero_titles[titleIndex]}
                        </span>
                        <span className="hero-static">HOSTING<br/>SOLUTIONS</span>
                    </h1>
                    <h2>THE RELIABLE WAY</h2>
                    <p>
                        We provide high-quality VPS hosting solutions that will let you
                        deploy your server in minutes.
                    </p>
                    <ul>
                        <li>High Performance and Reliability</li>
                        <li>Scalable Resources</li>
                        <li>Expert 24/7 Support</li>
                        <li>99.9% Uptime Guarantee</li>
                    </ul>
                    <button className="hero-button" onClick={handle_GetStarted}>Get Started</button>
                </div>
                <div className="hero-right">
                    <img src={heroImage} alt="VPS hosting illustration" />
                </div>
            </div>
            <div className={`features ${isDarkmode ? 'darkmode' : ''}`}>
                <div className="feature-card">
                    <div className="feature-icon">⚡</div>
                    <h3>High Performance</h3>
                    <p>
                        Our VPS servers are equipped with the latest hardware to ensure
                        maximum performance and reliability.
                    </p>
                </div>
                <div className="feature-card">
                    <div className="feature-icon">🔒</div>
                    <h3>Security Guarantee</h3>
                    <p>
                        We prioritize security with advanced protection measures to keep
                        your data safe and secure.
                    </p>
                </div>
                <div className="feature-card">
                    <div className="feature-icon">💬</div>
                    <h3>24/7 Support</h3>
                    <p>
                        Get real-time assistance from our dedicated support team available
                        24/7 to help you with any questions or issues.
                    </p>
                </div>
                <div className="feature-card">
                    <div className="feature-icon">🌐</div>
                    <h3>Global Network</h3>
                    <p>
                        Our VPS servers are distributed globally to provide you with the
                        best possible performance and reliability.
                    </p>
                </div>
                <div className="feature-card">
                    <div className="feature-icon">🔄</div>
                    <h3>Automated Backups</h3>
                    <p>
                        Sleep easy knowing your server data is safe and protected with our
                        regular automated backups.
                    </p>
                </div>
                <div className="feature-card">
                    <div className="feature-icon">👥</div>
                    <h3>Unlimited Bandwidth</h3>
                    <p>
                        Enjoy unlimited bandwidth with our VPS hosting plans, ensuring your
                        website or application runs smoothly.
                    </p>
                </div>
            </div>
            <TrustBox />
        </div>
    );
}

export default Home;
