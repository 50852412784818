import React from 'react';
import { Link } from 'react-router-dom';
import './Contact-Success.css';

const ContactSuccess = ({ isDarkmode, toggleDarkMode }) => {
    return (
        <div className={`success-page ${isDarkmode ? 'darkmode' : ''}`}>
            <h1>Thank you!</h1>
            <p>Your message has been sent successfully.</p>
            <Link to="/" className="back-link">Go back to Home</Link>
        </div>
    );
};

export default ContactSuccess;
