import React from 'react';
import './GameHosting.css';
import heroImage from "../images/hero.png";
import { faCheckCircle, faRocket, faShieldAlt, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const handleBuy = (plan_id) => {
    console.log('Buying Game Hosting Plan: ' + plan_id);

    const url_dict = {
        'BOT-01': 'https://billing2.fastvps.systems/index.php?rp=/store/rust-hosting/lowgrade-plan',
        'BOT-02': 'https://billing2.fastvps.systems/index.php?rp=/store/rust-hosting/crude-oil-plan',
        'BOT-03': 'https://billing2.fastvps.systems/index.php?rp=/store/rust-hosting/metal-plan',
    }

    // Open the URL in a new tab
    //window.open(url_dict[plan_id], '_blank');
    alert('This Item Is Currently Unavailable, You can Contact Us For A Setup of this instead of waiting for the store to be updated');
};
const GameHosting = ({ isDarkmode }) => {
    const rustPlans = [
        { id: 'BOT-01', description: 'Ideal For Your Personal Bot', name: "R2D2 Plan", price: "€0.49 EUR/month", cpu: "0.5 vCore (Xeon/Epyc)", memory: "512MB", storage: "1GB SSD", ports: "1 Port Allocations", databases: '0', protection: "Path.net", location: "UK/Germany" },
        { id: 'BOT-02', description: 'Ideal For A Pretty Heavy Bot', name: "Wall-E Plan", price: "€1.49 EUR/month", cpu: "1 vCore (Xeon/Epyc)", memory: "2GB", storage: "5GB SSD", ports: "2 Port Allocations", databases: '1', protection: "Path.net", location: "UK/Germany" },
        { id: 'BOT-03', description: 'Ideal For A Very Very Heavy Bot', name: "Bender Plan", price: "2.49 EUR/month", cpu: "2 vCore (Xeon/Epyc)", memory: "4GB", storage: "10GB SSD", ports: "4 Port Allocations", databases: '3', protection: "Path.net", location: "UK/Germany" },
    ];

    const PlanCard = ({ plan }) => (
        <div className={`plan-card ${isDarkmode ? 'darkmode' : ''}`}>
            <h4>{plan.name}</h4>
            <p><b>{plan.description}</b></p>
            <ul>
                <li><strong>Price:</strong> {plan.price}</li>
                <li><strong>CPU:</strong> {plan.cpu}</li>
                <li><strong>Memory:</strong> {plan.memory}</li>
                {plan.storage && <li><strong>Storage:</strong> {plan.storage}</li>}
                {plan.ports && <li><strong>Ports:</strong> {plan.ports}</li>}
                {plan.network && <li><strong>Network:</strong> {plan.network}</li>}
                <li><strong>Protection:</strong> {plan.protection}</li>
                {plan.location && <li><strong>Location:</strong> {plan.location}</li>}
                <button className="buy-button" onClick={() => handleBuy(plan.id)}>Buy Now</button>
            </ul>
        </div>
    );

    return (
        <div className={`page-content ${isDarkmode ? 'darkmode' : ''}`}>
            <div className={`game-hero ${isDarkmode ? 'darkmode' : ''}`}>
                <div className="game-hero-left">
                    <h1 id='game-title-vps' className="vps-hero-title">BOT HOSTING</h1>
                    <h3>Get Your Bots Online With Our Unrivalled Pricing</h3>
                    <h5>Stay Online 24/7 With Path.net. Ultra Strong DDoS Protection Included</h5>
                </div>
                <div className="hero-right">
                    <img src={heroImage} alt="Bot hosting illustration" />
                </div>
            </div>
            <div className='feature-container'>
                <div className='feature'>
                    <FontAwesomeIcon icon={faRocket} size='8x' />
                    <h2>Fast</h2>
                    <p>Experience blazing fast speeds with our state-of-the-art hardware, optimized networks, and low-latency connections. We prioritize speed to keep your services running smoothly and efficiently.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faShieldAlt} size='8x' />
                    <h2>Secure</h2>
                    <p>Boasting Leading DDoS Protection, On-Site Redundancy Routes, Around The Clock Monitoring. We Have Your Security In Mind At Every Point Of Your Services.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faCheckCircle} size='8x' />
                    <h2>Reliable</h2>
                    <p>With an industry-leading uptime guarantee, redundant infrastructure, and proactive maintenance, we ensure that your services are always available when you need them.</p>
                </div>
                <div className='feature'>
                    <FontAwesomeIcon icon={faPeopleGroup} size='8x' />
                    <h2>24/7 Support</h2>
                    <p>We Offer 24/7 Support For Our Clients, Ensuring their Bots Run Smoothly Without An Interuption</p>
                </div>
            </div>
            <div className="plans-container">
                <h2 className={`hosting-header ${isDarkmode ? 'darkmode' : ''}`}>Bot Hosting Plans</h2>
                <div className="plans-grid">
                    {rustPlans.map(plan => <PlanCard key={plan.name} plan={plan} />)}
                </div>
                
            </div>
        </div>
    );
}

export default GameHosting;
