import React, { useState } from 'react';
import './FAQ.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const FAQ = ({ isDarkmode }) => {
    const [visibleAnswers, setVisibleAnswers] = useState({});

    const toggleAnswer = (index) => {
        setVisibleAnswers((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const faqData = [
        {
            question: 'What services does Fastvps.Systems offer?',
            answer: 'Fastvps.Systems offers a range of hosting services including VPS hosting, dedicated servers, cloud hosting, and managed hosting solutions.',
        },
        {
            question: 'How do I contact customer support?',
            answer: 'You can contact our customer support team 24/7 via live chat, email at support@fastvps.systems, or by Using Our Ticket System.',
        },
        {
            question: 'Where are your data centers located?',
            answer: 'We Offer Data Centers in 2 Locations Currently, One in the United Kingdom and the Other in Germany. We are planning to expand to more locations in the future.',
        },
        {
            question: 'What is VPS hosting?',
            answer: 'VPS (Virtual Private Server) hosting provides you with a virtual environment that mimics a dedicated server but within a shared hosting environment. It offers more control, flexibility, and resources than standard shared hosting.',
        },
        {
            question: 'What operating systems do you support for VPS?',
            answer: 'We support a variety of operating systems including various distributions of Linux (Ubuntu, CentOS, Debian) and Windows Server.',
        },
        {
            question: 'Can I upgrade my VPS plan in the future?',
            answer: 'Yes, you can easily upgrade your VPS plan at any time through your account dashboard. Our customer support team can assist you with the upgrade process to ensure a smooth transition.',
        },
        {
            question: 'What payment methods do you accept?',
            answer: 'We accept all major credit cards and PayPal',
        },
        {
            question: 'How does the billing cycle work?',
            answer: 'Our billing cycles are flexible. You can choose to be billed monthly, quarterly, semi-annually, or annually, depending on your preference.',
        },
        {
            question: 'How do you ensure the security of my data?',
            answer: 'We prioritize security by implementing advanced firewalls, DDoS protection, regular security audits, and data encryption. We also offer optional services like managed backups and malware scanning.',
        },
        {
            question: 'What is your uptime guarantee?',
            answer: 'Fastvps.Systems guarantees a 99.0%* uptime on all our hosting services, ensuring your website remains accessible around the clock.\n\n*Scheduled maintenance and unforeseen incidents may affect uptime. Please refer to our Terms of Service for more details.',
        },
        {
            question: 'What kind of technical support do you provide?',
            answer: 'Our technical support team is available 24/7 to assist with any issues or questions you may have. We provide support via live chat, email, and phone.',
        },
        {
            question: 'Do you offer migration services?',
            answer: 'Yes, we offer free migration services for new customers. Our team will assist you in transferring your website and data from your previous host to Fastvps.Systems.',
        },
        {
            question: 'How can I access my control panel?',
            answer: 'You can access your control panel through our customer portal at billing2.fastvps.systems. Login with your credentials to manage your hosting services, domains, and more.',
        },
    ];

    return (
        <div className={`FAQ ${isDarkmode ? 'darkmode' : ''}`}>
            <h2>FAQ</h2>
            <div className="faq-section">
                {faqData.map((faq, index) => (
                    <div key={index} className="faq-question">
                        <h3 onClick={() => toggleAnswer(index)}>
                            {faq.question}
                            <FontAwesomeIcon
                                icon={visibleAnswers[index] ? faChevronUp : faChevronDown}
                                className="faq-icon"
                            />
                        </h3>
                        <p className={`faq-answer ${visibleAnswers[index] ? 'visible' : ''}`}>
                            {/* If the answer has \n, split it into separate paragraphs */}
                            
                            {faq.answer.split('\n').map((paragraph, index) => (
                                <span key={index}>
                                    {paragraph}
                                    <br />
                                </span>
                            ))}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
