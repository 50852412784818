import React, { useEffect, useState } from "react";
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import './ScrollBar.css';

import Footer from "./components/Footer";
import NavBar from "./components/NavBar";

import About from "./Pages/About";
import Home from "./Pages/Home";
import FAQ from "./Pages/FAQ";
import VPSHosting from "./Pages/VPSHosting";
import Contact from "./Pages/Contact";
import GameHosting from "./Pages/GameHosting";

import ContactSuccess from "./Pages/Contact-Success";




// Analytics
import Plausible from 'plausible-tracker'
import WebHosting from "./Pages/WebHosting";
import FourOFour from "./Pages/FourOFour";
import TermsOfService from "./Pages/TermsOfService";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import BlogHome from "./Pages/BlogPages/BlogHome";
import ArticleOne from "./Pages/BlogPages/ArticleOne";
import DiscordBotHosting from "./Pages/DiscordBotHosting";
import UnderConstruction from "./Pages/UnderContruction";

const { enableAutoPageviews, enableAutoOutboundTracking } = Plausible({
  trackLocalhost: true,
  domain: 'fastvps.systems',
  apiHost: 'https://plausible.cyphersoftware.dev'
})

enableAutoOutboundTracking()
enableAutoPageviews()

function App() {
  const [isDarkmode, setIsDarkmode] = useState(() => localStorage.getItem('darkmode') === 'true');

  const toggleDarkMode = () => {
    setIsDarkmode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('darkmode', newMode);
      return newMode;
    });
  };

  useEffect(() => {
    document.body.classList.toggle('darkmode', isDarkmode);
  }, [isDarkmode]);

  return (
    <Router>
      <div className={`App ${isDarkmode ? 'darkmode' : ''}`}>
        <NavBar isDarkmode={isDarkmode} toggleDarkMode={toggleDarkMode} />
        <Routes>
          <Route path="/" element={<Home isDarkmode={isDarkmode} />} />
          <Route path="/about" element={<About isDarkmode={isDarkmode} />} />
          <Route path="/contact" element={<Contact isDarkmode={isDarkmode} />} />
          <Route path="/contact/success" element={<ContactSuccess isDarkmode={isDarkmode} />} />
          <Route path="/faq" element={<FAQ isDarkmode={isDarkmode} />} />
          <Route path="/services/vps-hosting" element={<VPSHosting isDarkmode={isDarkmode} />} />
          <Route path="/services/game-hosting" element={<GameHosting isDarkmode={isDarkmode} />} />
          <Route path="/services/web-hosting" element={<WebHosting isDarkmode={isDarkmode} />} />
          <Route path='/terms' element={<TermsOfService isDarkmode={isDarkmode} />} />
          <Route path="/privacy" element={<PrivacyPolicy isDarkmode={isDarkmode} />} />
          <Route path="/blog" element={<UnderConstruction isDarkmode={isDarkmode} />} />
          <Route path="/blog/article/1" element={<UnderConstruction isDarkmode={isDarkmode} />} />
          <Route path="/resources" element={<UnderConstruction isDarkmode={isDarkmode} />} />

          <Route path="/services/discord-bot-hosting" element={<DiscordBotHosting isDarkmode={isDarkmode} />} />
          <Route path="*" element={<FourOFour isDarkmode={isDarkmode} /> } />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
